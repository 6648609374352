<template>
  <div>
    <div id="navbar" class="w-100 navbar-container">
      <div class="">
        <b-navbar-brand to="/" class="logo-container bg-nav" exact>
          <img
            src="/img/SOUTHBAY_LOGO_BLUE.png"
            alt="Southbay at River's Edge Logo"
            fluid
            class="navbarLogo"
          />
        </b-navbar-brand>
      </div>
      <div class="register-container">
        <p
          class="freight-big-pro-book text-white register-link"
          v-scroll-to="{ el: '#register', offset: -100 }"
          role="button"
        >
          <nuxt-link :to="{ path: '/'}" class="text-white text-decoration-none" @click.native="handleScroll('register')">
            REGISTER NOW
          </nuxt-link>
        </p>
        <div class="menu-collapsed" id="menu-custom">
          <div id="bar"></div>
          <nav>
            <ul class="freight-big-pro-book text-uppercase text-left">
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none">
                  Home
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none" @click.native="handleScroll('nourished_section')">
                  Location
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none" @click.native="handleScroll('sights')">
                  Homes
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none" @click.native="handleScroll('wasaga')">
                  Amenities
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none" @click.native="handleScroll('heightened-perspective')">
                  Builder
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/blog'}" class="color-black text-decoration-none">
                  Blog
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="{ path: '/'}" class="color-black text-decoration-none" @click.native="handleScroll('register')">
                  Register
                </nuxt-link>
              </li>
            </ul>
          </nav>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jq from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      fadeInElement_nav: null,
      testAnim: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    this.initNavElReveal_homePage();
    jq("#menu-custom").click(function () {
      jq(this).toggleClass("menu-expanded");
    });
  },
  methods: {
    handleScroll(anchorId){
      setTimeout(() => {
        const anchor = document.querySelector(`#${anchorId}`)

        // Check if the anchor has been found
        if (anchor) {
          window.scrollTo({
            // Scroll so that the anchor is at the top of the view
            top: anchor.getBoundingClientRect().top + window.pageYOffset
          })
        }
      }, 750)
    },
    initNavElReveal_homePage() {
      let testAnim = this.testAnim;
      testAnim = gsap;
      var scrollTimeline = testAnim
        .timeline({ paused: true })
        .set("#navbar", {
          opacity: 0,
        })
        .set(".nav-callout", {
          opacity: 0,
        })
        .to("#navbar", {
          opacity: 1,
          duration: 0.3,
        })
        .to(".nav-callout", {
          opacity: 1,
          duration: 0.3,
        });
      this.testAnimTrigger = ScrollTrigger.create({
        trigger: ".nourished_section",
        start: "top bottom",
        end: "+=30%",
        animation: scrollTimeline,
        scrub: 0.3,
      });
    },
    beforeDestroy() {
      setTimeout(() => {
        ScrollTrigger.getAll().forEach((t) => t.kill());
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.nav-callout {
  position: fixed;
  top: 81px;
  width: 100%;
  padding: 15px 47px;
  z-index: 2;

  @media screen and (max-width: 850px) {
    top: 76px;
  }

  @media screen and (max-width: 630px) {
    top: 62px;
  }
}

#navbar {
  padding: 10px 0px 10px 50px;
  opacity: 0;
  z-index: 1030;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #f4f3f1;
  -webkit-box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  @media screen and (max-width: 630px) {
    padding: 10px 0px 10px 20px;
  }
  .links-container {
    position: absolute;
    width: 200px;
    left: 100px;
    top: 18px;
    p {
      font-size: 12px;
      padding-right: 30px;
    }
  }
  .register-container {
    background-color: #27576b;
    position: absolute;
    right: 100px;
    top: 33%;
    padding: 0px 20px 0px 20px;
    p {
      margin: 0;
      font-size: 18px;
    }
    .b-a {
      transition: all 0.25s;
      content: "";
      position: absolute;
      right: 0;
      height: 3px;
      width: 30px;
      background-color: #0b2340;
    }
    .menu-collapsed {
      transition: all 0.25s;
      position: fixed;
      top: 20px;
      right: 37px;
      height: 36px;
      width: 36px;
      z-index: 999999;
      cursor: pointer;
      ul {
        transition: all 0s;
        position: fixed;
        left: -9000px;
      }
    }
    #bar {
      @extend .b-a;
      position: fixed;
      right: 40px;
      top: 37px;
      // display: none;
      &:before {
        @extend .b-a;
        top: -8px;
        // width: 20px;
      }
      &:after {
        @extend .b-a;
        top: 8px;
        // width: 20px;
        height: 3px;
      }
      @media screen and (max-width: 630px) {
        right: 10px;
        top: 30px;
      }
    }
    .b-a-expanded {
      transition: all 0.25s;
      top: -0px;
    }
    .menu-expanded {
      transition: all 0.25s;
      text-align: right;
      line-height: 75px;
      height: 100%;
      width: 100%;
      border-radius: 0px;
      top: 0;
      left: 0;
      background-color: #ebf1f2;
      #fp-nav ul li {
        display: none;
      }
      ul {
        list-style-type: none;
        transition: all 0s;
        position: absolute;
        left: 80px;
        top: 20%;
        z-index: 99999;
        @media only screen and (max-width: 640px) {
          left: 0px;
        }
      }
      li {
        transition: all 0.15s;
        text-decoration: none;
        font-size: 7em;
        line-height: 0.8em;
        color: #0b2340;
        display: block;
        &:hover {
          font-style: italic;
        }
        @media only screen and (max-width: 640px) {
          font-size: 4rem;
          line-height: 3.5rem;
        }

        a {
          color: #0b2340;
        }
      }
      #bar {
        transition: all 0.25s;
        background-color: transparent;
        &:before {
          @extend .b-a-expanded;
          transform: rotate(45deg);
        }
        &:after {
          @extend .b-a-expanded;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .line {
    position: absolute;
    padding: 0;
    top: 39px;
    border-bottom: 1.5px solid $m-blue;
    width: 100%;
  }
  .logo-container {
    width: 250px;
    z-index: 99999;
    position: relative;
    margin: 0;
  }
  .navbarLogo {
    width: 100%;
    max-width: 200px;
    cursor: pointer;
  }
  @media screen and (max-width: 850px) {
    .navbarLogo {
      max-width: 180px;
    }
    .logo-container {
      width: 200px;
    }
    .register-container {
      right: 50px;
    }
    .links-container {
      left: 50px;
    }
  }
  @media screen and (max-width: 630px) {
    .navbarLogo {
      max-width: 122px;
    }
    .logo-container {
      width: 131px;
    }
    .register-container {
      right: 50px;
      top: 22px;
      padding: 0px 10px 0px 10px;
      p {
        font-size: 11.8px;
      }
    }
    .links-container {
      left: 10px;
      top: 21px;
      p {
        font-size: 10.5px;
        padding-right: 10px;
      }
    }
  }
}
</style>
